.page-wrapper{
    background-image: url("../../images/fadepixelswip4.png");
    min-height: calc(100vh - 200px);
}

.page-content {
    min-height: calc(100vh - 200px);
}

.page-wrapper p {
    @apply my-4;
    @apply first:mt-0;
    line-height: 36px;
}

.page-wrapper ul li {
    @apply my-2;
}

.page-wrapper p, .page-wrapper ul li {
    font-size: 17px;
    color: #545454;
}

.page-title {
    background: -webkit-linear-gradient(#bfe3ff, #2e72a7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0px 0px #00000000;
    font-size: 39px;
    letter-spacing: 9px;
    font-weight: 300;
}

@media (max-width: 640px) {
    .page-wrapper p {
        font-size: 16px;
        line-height: 31px;
    }
}

.page-title-banner {
    height: 60px;
    left: 0;
    width: 100%;
}

.page-title-wrapper {
    margin-bottom: 14px;
}

.about .page-title-banner::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(90deg, white 178px, #ffffff54 100%);
}

.services .page-title-banner::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(90deg, white 271px, #ffffff54 100%);
}

.clients .page-title-banner::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(90deg, white 271px, #ffffff54 100%);
}

.our-team .page-title-banner::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(90deg, white 271px, #ffffff54 100%);
}

.contact .page-title-banner::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(90deg, white 271px, #ffffff54 100%);
}

@media only screen and (max-width: 470px) {
    .page-title-banner{
        display: none;
    }

    .page-title::before {
        content: '';
        width: 90%;
        height: 2px;
        position: absolute;
        background: #e5e5e5;
        top: 68px;
        background-image: linear-gradient(45deg, #e9e9e9 0%, white 100%);
        left: 0;
    }
}