.arc-logo-img {
    width: 92px;
}

.mail-icon{
    width: 23px;
}

.arcblue-gradient {
    background-image: linear-gradient(180deg, #91cfff, #529FDB);
}

.button-shadow {
    box-shadow: 0px 3px 5px #00000030;
}

header li {
    transition: background-image 300ms ease;
}

header li:hover {
    background-image: linear-gradient(180deg, #a5a5a5, #656565);
}

.nav-list {
    z-index: 11;
    width: 100%;
}

.arc-mobile-btn {
    display: none;
}

.arc-contact-btn-btm {
    display: none;
}

@media only screen and (max-width: 460px ) {
    nav.not-active .nav-list {
        @apply pointer-events-none;
    }
    .arc-contact-btn-top {
        display: none;
    }
    .arc-contact-btn-btm {
        @apply flex;
        width: fit-content;
    }
    .arc-logo-img{
        width: 66px;
    }
    .arc-mobile-wpr{
        padding: 23px;
        z-index: 12;
        box-shadow: 0px 0px 22px #0000003d;
    }
    .nav-list{
        position: absolute;
        top: 80px;
        height: calc(100vh - 80px);
    }
    .nav-list li {
        @apply p-6;
        font-size: 21px;
        text-shadow: 0px 0px 15px #00000038;
        width: 100%;
    }
    .nav-list > *{
        @apply flex-col;
    }
    nav.not-active .nav-list {
        opacity: 0;
        transition: 400ms linear opacity;
    }
    nav.active .nav-list {
        transition: 400ms linear opacity;
        opacity: 1;
    }
    .arc-mobile-btn{
        display: block;
    }
}

.arc-mobile-btn {
    width: 31px;
    cursor: pointer;
}

.arcwhite-gradient {
    background-image: linear-gradient(180deg, #ffffff, #cbcbcb);
    color: #656565;
}

.arc-mobile-btn span {
    display: block;
    width: 100%;
    height: 3px;
    background: #656565;
    transition: all .3s;
    position: relative;
}

.bar + .bar {
    margin-top: 7px;
}

nav.active .arc-mobile-btn span:nth-child(1) {
    animation: ease .7s top forwards;
}

nav.not-active .arc-mobile-btn span:nth-child(1) {
    animation: ease .7s top-2 forwards;
}

nav.active .arc-mobile-btn span:nth-child(2) {
    animation: ease .7s scaled forwards;
}

nav.not-active .arc-mobile-btn span:nth-child(2) {
    animation: ease .7s scaled-2 forwards;
}

nav.active .arc-mobile-btn span:nth-child(3) {
    animation: ease .7s bottom forwards;
}

nav.not-active .arc-mobile-btn span:nth-child(3) {
    animation: ease .7s bottom-2 forwards;
}

@keyframes top {
    0% {
        top: 0;
        transform: rotate(0);
    }
    50% {
        top: 10px;
        transform: rotate(0);
    }
    100% {
        top: 10px;
        transform: rotate(45deg);
    }
}

@keyframes top-2 {
    0% {
        top: 10px;
        transform: rotate(45deg);
    }
    50% {
        top: 10px;
        transform: rotate(0deg);
    }
    100% {
        top: 0;
        transform: rotate(0deg);
    }
}

@keyframes bottom {
    0% {
        bottom: 0;
        transform: rotate(0);
    }
    50% {
        bottom: 10px;
        transform: rotate(0);
    }
    100% {
        bottom: 10px;
        transform: rotate(135deg);
    }
}

@keyframes bottom-2 {
    0% {
        bottom: 10px;
        transform: rotate(135deg);
    }
    50% {
        bottom: 10px;
        transform: rotate(0);
    }
    100% {
        bottom: 0;
        transform: rotate(0);
    }
}

@keyframes scaled {
    50% {
        transform: scale(0);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes scaled-2 {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
