body {
    -webkit-tap-highlight-color: transparent;
}

ul.list-disc li {
    @apply my-2;
}

.arc-footer>div::after {
    content: '';
    position: absolute;
    top: -5px;
    width: 100%;
    height: 5px;
    background-image: linear-gradient(90deg, #3f88c1, #91cfff, #3f88c1);
    left: 0;
}

.bg-arcGreyGradient {
    background-image: linear-gradient(180deg , #818181, #656565);
}

.arcblue-gradient-light {
    background-image: linear-gradient(180deg, #9ed2fb, #69b5f1);
}

.arc-footer-home {
    display: none;
}