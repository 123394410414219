.client-lists ul li{
    font-size: 15px !important;
}

.client-lists {

}

@media only screen and (max-width: 600px){
    .client-lists {
        @apply flex-col !important;
    }
    .client-lists ul {
        @apply relative;
        @apply my-2;
    }
    ul:not(:last-child)::after {
        content: '';
        width: 100%;
        height: 1px;
        background-image: linear-gradient(45deg,transparent, #e9e9e9, transparent);
        position: absolute;
        left: 0;
        bottom: -0.5rem;
    }
}