.arc-landing-hero {
    background-image: url("../../images/windmillFullscreenCompressed10.jpeg");
    position: absolute;
    width: 100%;
    height: auto;
    min-height: calc(100vh - 136px);
    background-size: cover;
}

.arc-landing-hero::after {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAAB9JREFUGFdjjFzx5P/yCBlGBigA8eEcmCCIJkEQm5kA2/gNzv+1jOYAAAAASUVORK5CYII=);
    background-repeat: repeat;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.hero-slide {
    /* max-width: 800px; */
    font-weight: 700;
    font-size: 30px;
    line-height: 49px;
    text-shadow: 0px 0px 4px #00000085;
    @apply p-4;
}
.swiper-wrapper {
    padding-bottom: 54px;
}

.swiper-pagination-fraction {
    bottom: -6px;
    left: 0;
    width: 100%;
}

.swiper-pagination-bullet-active {
    background: white !important;
}

@media only screen and (max-width: 460px ) {
    .arc-landing-hero {
        min-height: calc(100vh - 80px);
    }
    .swiper-slide {
        font-size: 25px;
        text-shadow: -2px 0px 3px #2e2e2eb3;
        padding: 0px 23px;
        line-height: 38px;
        font-weight: 700;
    }
}

@media only screen and (max-width: 390px) {
    .swiper-slide{
        font-size: 22px;
    }
}

@media only screen and (max-width: 330px) {
    .arc-landing-hero>div {
        @apply mt-3;
    }
    .swiper-pagination-horizontal {
        bottom: 50px;
    }
    .swiper-slide {
        font-size: 19px;
    }
}